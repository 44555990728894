<style
  lang="postcss"
  scoped
>
  .header {
    position: fixed;
    top: 0;
    z-index: 999;
    transition: background 0.2s linear;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.42) 0%, transparent 90%);
    width: inherit;
    height: 0.44rem;

    &:before {
      display: none;
    }

    &.bg {
      background: rgba(255, 255, 255);

      &:before {
        background: #eee;
        display: block;
      }

      & .cell {
        color: #666;

        & a {
          color: currentColor;
        }
      }

      & .center {
        & .search {
          background: #f8f8f8;
        }
      }
    }

    &.navBar {
      top: calc(env(safe-area-inset-top) + 0.47rem);
    }

    & .cell {
      width: 0.44rem;
      height: 0.44rem;
      text-align: center;
      color: #fff;

      & img {
        width: 0.32rem;
        height: 0.32rem;
        margin: 0.06rem 0.06rem;
      }

      & a {
        color: inherit;
      }

      & .iconfont {
        font-size: 0.24rem;
        padding: 0.1rem;
        display: block;
        line-height: 1;
      }
    }

    & .center {
      margin-left: 0.06rem;
    }

    & .site-name {
      padding-left: 0.1rem;
      line-height: 1;
      padding-right: 0.1rem;
    }
  }
</style>
<template>
  <header
    class="flex-center-center border-bm header"
    :class="{bg, navBar: navBar && !isKidsWant}"
  >
    <div
      class="cell"
      v-if="!noLeft"
    >
      <slot name="left"></slot>
    </div>
    <slot name="qm-location"/>
    <slot name="logo"/>
    <div
      class="border-lt site-name"
      v-if="showName"
    >
      <slot name="siteName"/>
    </div>
    <div
      class="center flex-1"
      :style="{marginRight: noRight ? '0.1rem': 0}"
    >
      <my-search/>
    </div>
    <div
      class="cell"
      v-if="!noRight"
    >
      <slot name="right"></slot>
    </div>
  </header>
</template>
<script>
  import Search from './Search.vue'
  import { siteConfig } from '../config'

  const { navBar = false, isKidsWant } = siteConfig

  export default {
    name: 'MyHeader',
    props: {
      noLeft: {
        type: Boolean,
        default: false
      },
      bgFixed: {
        type: Boolean,
        default: false
      },
      transparent: {
        type: Boolean,
        default: false
      },
      noRight: {
        type: Boolean,
        default: false
      },
      showName: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        bg: false,
        navBar,
        isKidsWant
      }
    },
    components: {
      [Search.name]: Search
    },
    watch: {
      transparent (curr) {
        if (curr) {
          window.removeEventListener('scroll', this.headerBg)
        } else {
          window.addEventListener('scroll', this.headerBg)
        }
      }
    },
    methods: {
      headerBg () {
        let scrollHeight = document.scrollingElement.scrollTop || document.body.scrollTop || window.pageYOffset
        this.bg = scrollHeight >= 125 * this.$root.radio
      },
      initiation () {
        if (!this.transparent) {
          if (!this.bgFixed) {
            window.addEventListener('scroll', this.headerBg)
          } else {
            this.bg = true
          }
        }
      }
    },
    created () {
      this.initiation()
    },
    activated () {
      this.initiation()
    },
    destroyed () {
      window.removeEventListener('scroll', this.headerBg)
    }
  }
</script>
