<style
  lang="postcss"
  scoped
>
  .qm-location {
    color: currentColor;

    & .icon-location {
      font-size: 0.14rem;
    }

    & .status {
      margin-left: 0.05rem;
    }

    & .address {
      max-width: 2.4rem;
    }

    &.short {
      & .icon-location {
        color: var(--base-color);
      }

      & .address {
        max-width: 1.2rem;
      }

      & .icon-down {
        display: none;
      }

      & .flex-column {
        line-height: 1;

        & .status {
          font-size: 0.08rem;
          color: #999;
          margin-left: 0;
          margin-top: 0.02rem;
          text-indent: 1.8em;
        }
      }
    }
  }
</style>
<template>
  <router-link
    class="flex-left-center text-1 qm-location"
    :class="{short}"
    to="/qingmei/location"
  >
    <div
      class="flex"
      :class="{'flex-column': short}"
    >
      <div class="flex-left-center text-1">
        <span class="iconfont icon-location"></span>
        <span class="address text-1">{{ geoCode ? errorMessage : address }}</span>
      </div>
      <small class="status">{{ deliverMessage }}</small>
    </div>
    <span class="iconfont icon-down"></span>
  </router-link>
</template>
<script>
  export default {
    name: "QmLocation",
    props: {
      short: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        latitude: "",
        longitude: "",
        errorMessage: "",
        geoCode: 0,
        address: "",
        deliverMessage: "",
        select: {}
      }
    },
    methods: {
      initiation () {
        try {
          const select = JSON.parse(sessionStorage.getItem("qingmei-geocoding-select"))
          if (select.addr === this.select.addr && select.addr) {
            return
          }
          const res = JSON.parse(sessionStorage.getItem("qingmei-geocoding-info"))
          this.address = select?.name || res.sematic_description || res.formatted_address
          this.select = select
          if (this.errorMessage) {
            this.errorMessage = ""
          }
        } catch (e) {}
      },
      getLocation () {
        if (this.loading2) {
          return
        }
        this.loading2 = true
        this.initiation()
        navigator.geolocation.getCurrentPosition(res => {
          let latitude = res.coords.latitude // 纬度
          let longitude = res.coords.longitude // 经度
          // 通过经纬度获取位置
          this.latitude = latitude
          this.longitude = longitude
          this.getAddress()
          this.getDeliver()
        }, (e) => {
          this.geoCode = e.code
          this.errorMessage = "定位失败,点击重试"
          this.loading2 = false
        }, {
          enableHighAccuracy: true,
          timeout: 4e3,
          maximumAge: 6e4
        })
        return false
      },
      getAddress () {
        this.$http.get("", {
          params: {
            act: "qmapi",
            op: "queryGeocodingInfo",
            lat: this.latitude,
            lng: this.longitude
          },
          loading: false
        }).then(res => {
          if (!res) {
            return
          }
          const str = JSON.stringify(res.datas)
          sessionStorage.setItem("qingmei-geocoding-info", str)
          this.address = this.select?.name || res.datas.sematic_description || res.datas.formatted_address
          window.eventBus.$emit('qingmei-geocoding-change', str)
        }).finally(() => {
          this.loading2 = false
        })
      },
      getDeliver () {
        this.deliverMessage = ''
        this.$http.get("", {
          params: {
            act: "qmapi",
            op: "getNearShop",
            lng: this.select?.location?.lng || this.longitude,
            lat: this.select?.location?.lat || this.latitude
          },
          loading: false
        }).then(res => {
          if (!res) {
            return
          }
          this.deliverMessage = res.datas.tips
        })
      }
    },
    mounted () {
      this.getLocation()
      window.eventBus.$on('qingmei-relocation', this.getLocation)
    },
    activated () {
      this.initiation()
      this.getDeliver()
    }
  }
</script>
