<style lang="postcss">
  @import "./css/index.css";

  #app {
    width: inherit;

    & > div {
      width: inherit;

      & > .tab-view {
        width: inherit;
      }

      & [class^='page-'] {
        width: inherit;
      }
    }
  }

  .site-lock {
    padding: 0.4rem 0.1rem 0;
    text-align: center;
    background: #fff;
    height: 100vh;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    left: 0;
  }
</style>
<template>
  <div
    id="app"
    v-cloak
    :class="{
    'has-nav': useNav && !isKidsWant,
    'theme-2': $root['theme'] === 2,
    'theme-3': $root['theme'] === 3,
    'theme-4': $root['theme'] === 4,
    'kids-want-app': $root.nativeBar.isApp
  }"
    v-if="!shutdownSite"
  >
    <template v-if="useNav">
      <kids-want-nav v-if="isKidsWant"/>
      <my-nav v-else/>
    </template>
    <my-private @pageLoad="removeLoading">
      <float-back-button v-if="showGlobalBackButton"/>
      <transition
        :name="direction['direction']"
        @after-enter="removeLoading"
      >
        <keep-alive include="SearchList,TabView,PageVenue,DangQi,Store">
          <router-view/>
        </keep-alive>
      </transition>
      <my-phone/>
      <my-home-page/>
      <my-home-jingjiniao/>
      <kids-want-float/>
      <drag-location/>
      <order-notification v-if="openOrderNotification"/>
    </my-private>
    <tool-bar/>
    <div
      class="mint-indicator-wrapper"
      style="padding: 0.15rem;width: auto;"
      v-if="loading.loading"
    >
      <mt-spinner type="double-bounce"/>
    </div>
  </div>
  <div
    class="site-lock"
    v-else
  >
    系统维护中！
  </div>
</template>
<script>
  import { Nav } from "./components/index"
  import MyPrivate from "./components/Private"
  import MyPhone from "./components/Phone"
  import MyHomePage from "./components/HomePage"
  import { mapState } from "vuex"
  import { siteConfig } from "./config"
  import { Spinner } from "mint-ui"
  import ToolBar from "@/components/ToolBar"
  import MyHomeJingjiniao from "@/components/HomeJingjiniao.vue"

  const {
    navBar = false,
    shutdownSite = false,
    isKidsWant = false,
    openOrderNotification = false,
    showGlobalBackButton = false
  } = siteConfig

  export default {
    name: "App",
    components: {
      MyHomeJingjiniao,
      ToolBar,
      MyPhone,
      [Nav.name]: Nav,
      MyPrivate,
      [Spinner.name]: Spinner,
      MyHomePage,
      "KidsWantFloat": () => import("@/components/KidsWant/Float"),
      "KidsWantNav": () => import("@/components/KidsWant/Nav"),
      "DragLocation": () => import("@/components/DragLocation"),
      "OrderNotification": () => import("@/components/OrderNotification"),
      "FloatBackButton": () => import("@/components/FloatBackButton.vue")
    },
    data () {
      return {
        useNav: navBar,
        shutdownSite,
        isKidsWant,
        openOrderNotification,
        showGlobalBackButton
      }
    },
    computed: {
      ...mapState(["direction", "loading"])
    },
    methods: {
      removeLoading () {
        // 移除loading图片
        let img = document.querySelector("#index-loading")
        if (img) {
          img.remove()
        }
        // 触发滚动行为 配合 route.js 工作
        window.eventBus.$emit("routeScrollBehavior")
      }
    },
    created () {
      if (this.shutdownSite) {
        this.removeLoading()
      }
    }
  }
</script>
